import React, {
  useEffect,
  useState,
} from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  Tabs,
  Typography,
  Tab,
  Modal,
  Button,
  TextField,
  Snackbar,
} from "@material-ui/core";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import Input from "@mui/material/Input";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Add,
  Edit,
  Delete,
} from "@material-ui/icons";
import { validateEmail } from "../Services/Email";
import Switch from "@mui/material/Switch";
import { baseURL } from "../Db/axios";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  justifyContent: "space-between",
  border: "1px solid #000",
  padding: "25px",
  boxShadow: 24,
  p: 6,
};

const useStyles = makeStyles((tenant) => ({
  addButton: {
    padding: "6px 27px",
    justifyContent: "space-between",
    background: "#c94b0b",
    color: "white",
  },
  cancelBtn: {
    marginLeft: "246px",
    padding: "6px 14px",
    justifyContent: "space-between",
    background: "#c94b0b",
    color: "white",
  },
  alignment: {
    justifyContent: "space-between",
  },
  CompanyListTitle: {
    [tenant.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
    marginTop: "5px",
    color: "#c94b0b",
    display: "flex",
    padding: "10px",
    fontSize: "24px",
    fontWeight: "15rem",
    justifyContent: "center",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#c94b0b",
    },
  },
  root: {
    margin: "25px 15px 25px 25px",
    boxShadow: "0 0 12px #d6d6d6",
    justifyContent: "spacebetween",
  },
  tabspace: {
    marginTop: "2rem",
  },
}));

const CompanyList = () => {
  const classes = useStyles();

  const [tenant, setTenant] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [editModalOpen, setEditModalOpen] =
    useState(false);
  const [deleteModalOpen, setDeleteModalOpen] =
    useState(false);
  const [selectedId, setSelectedId] =
    useState(null);
  const [itemName, setItemName] = useState(null);
  const [newTenantData, setNewTenantData] = useState();
  const [formValues, setFormValues] = useState({
    name: "",
    email: [""],
    TId: [""],
    tenantIds: [],
  });

  const [tenantError, setTenantError] = useState("");
  const [success, showSuccess] = useState(false);
  const [error, setError] = useState("");

  const fetchTenantCompany = () => {
    axios
      .get(`${baseURL}/tenant/show`)
      .then((res) => {
        setTenant(res.data);
        return res.data;
      })
      .catch((e) => {
        return false;
      });
  };

  useEffect(() => {
    fetchTenantCompany();
  }, [newTenantData]);

  async function getData(index) {
    axios
      .get(
        `https://login.microsoftonline.com/${formValues?.TId[index]}/.well-known/openid-configuration`
      )
      .then((res) => {
        const myUrl = new URL(
          res.data.authorization_endpoint
        );
        let tenantIdValues = formValues.tenantIds;
        tenantIdValues[index] =
          myUrl.pathname.split("/")[1];
        setFormValues({
          ...formValues,
          tenantIds: tenantIdValues,
        });
      })
      .catch((e) => {
        console.log(e);
        setTenantError(
          `Unable to find tenant Id for domain: ${formValues?.TId[index]} Please Enter correct domain!`
        );
      });
  }

  const url = activeTab === 0 ? "tenant" : null;

  const setEditClick = (t) => {
    let { _id, name } = t;
    const newValues = {
      name: name,
      email: t.email,
      TId: t.TId.map((i) => i.name),
      tenantIds: t.TId.map((i) => i.id),
    };
    setFormValues(newValues);
    setEditModalOpen(true);
    setSelectedId(_id);
    setItemName(name);
  };

  const setDeleteClick = (_id, name) => {
    setDeleteModalOpen(true);
    setSelectedId(_id);
    setItemName(name);
  };

  const handleModalClose = () => {
    setDeleteModalOpen(false);
    setEditModalOpen(false);
    setSelectedId(null);
    setItemName(null);
    setFormValues({
      name: "",
      email: [""],
      TId: [""],
      tenantIds: [""],
    });
  };

  const confirmDelete = () => {
    axios
      .delete(
        `${baseURL}/${url}/delete/${selectedId}`
      )
      .then((res) => {
        handleModalClose();
        fetchTenantCompany();
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const confirmAddEdit = () => {
    if (
      formValues.email === undefined &&
      formValues.email !== ""
    ) {
      setFormValues({
        ...formValues,
      });
    }

    if (
      formValues.TId === undefined &&
      formValues.TId !== ""
    ) {
      setFormValues({
        ...formValues,
      });
    }

    if (selectedId) {
      const newTId = formValues.TId.map(
        (i, index) => {
          return {
            name: i,
            id: formValues.tenantIds[index],
          };
        }
      );
      axios
        .put(
          `${baseURL}/${url}/edit/${selectedId}`,
          {
            name: formValues.name,
            email: formValues.email,
            TId: newTId,
          }
        )
        .then((res) => {
          handleModalClose();
          fetchTenantCompany();
        });
    } else if (!formValues.email === "") {
      setError("Email is required");
    } else if (!validateEmail(formValues.email)) {
      setError("Please enter valid email");
    } else {
      const newTId = formValues.TId.map(
        (i, index) => {
          return {
            name: i,
            id: formValues.tenantIds[index],
          };
        }
      );
      axios
        .post(`${baseURL}/${url}/save`, {
          name: formValues.name,
          email: formValues.email,
          TId: newTId,
        })
        .then(async (res) => {
          setNewTenantData(res.data);
          handleModalClose();
          fetchTenantCompany();
        });
      handleModalClose();
      setError("");
      showSuccess(true);
    }
  };

  const handleTabChange = (event, nextValue) => {
    fetchTenantCompany();
    setActiveTab(nextValue);
  };

  const Alert = (props) => (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
    />
  );

  const TabPanel = (props) => {
    const { children, value, index, ...other } =
      props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const onSwitchChange = (data) => {
    axios
      .put(`${baseURL}/${url}/flagset`, {
        id: data?._id,
        flag: !data?.flag,
      })
      .then((res) => {
        setTenant(res.data)
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <Snackbar
        open={error !== ""}
        autoHideDuration={2000}
        onClose={() => setError("")}
      >
        <Alert
          onClose={() => setError("")}
          severity="error"
        >
          {error} Something went wrong
        </Alert>
      </Snackbar>
      <Snackbar
        open={tenantError !== ""}
        autoHideDuration={2000}
        onClose={() => setTenantError("")}
      >
        <Alert
          onClose={() => setTenantError("")}
          severity="error"
        >
          {tenantError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => showSuccess(false)}
      >
        <Alert
          onClose={() => showSuccess(false)}
          severity="success"
        >
          Information Added Successfully
        </Alert>
      </Snackbar>

      <Typography
        className={classes.CompanyListTitle}
      >
        Tenant Company List
      </Typography>
      <Typography className={classes.root}>
        <Box
          sx={{
            width: "100%",
            boxShadow: 20,
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              padding: "10px 30px",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              className={classes.tabs}
            >
              <Tab label="Tenant Info" />
            </Tabs>
          </Box>
          <TabPanel value={activeTab} index={0}>
            <Typography
              style={{ display: "flex" }}
              className={classes.alignment}
            >
              <Typography variant="h5">
                Tenant Info{" "}
              </Typography>
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={() =>
                  setEditModalOpen(true)
                }
                color="error"
                startIcon={<Add />}
              >
                Add Tenant Company
              </Button>
            </Typography>
            <Table
              aria-label="simple table"
              width={"70%"}
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    Company Name
                  </TableCell>
                  <TableCell>Admin</TableCell>
                  <TableCell>
                    company domain AND TenantId
                  </TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Delete</TableCell>
                  <TableCell>Flag</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenant.map((t, index) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {t.name}
                      </TableCell>
                      <TableCell>
                        {t.email.map((mail) => (
                          <p
                            style={{
                              margin: "5",
                            }}
                          >
                            {mail}
                          </p>
                        ))}
                      </TableCell>
                      <TableCell>
                        {t.TId.map((tId) => {
                          return (
                            <p
                              style={{
                                margin: "5",
                              }}
                            >
                              {tId.name} ({tId.id}
                              )
                            </p>
                          );
                        })}
                      </TableCell>
                      <TableCell>
                        <Edit
                          onClick={() =>
                            setEditClick(t)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Delete
                          onClick={() =>
                            setDeleteClick(
                              t._id,
                              t.name
                            )
                          }
                          color="error"
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={t?.flag}
                          onChange={() =>
                            onSwitchChange(t)
                          }
                          inputProps={{
                            "aria-label":
                              "controlled",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TabPanel>
        </Box>
      </Typography>

      {deleteModalOpen ? (
        <Modal
          open={deleteModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className={classes.modal}
          >
            <Typography
              variant="h5"
              className={classes.margin}
            >
              Delete{" "}
              {activeTab === 0
                ? "Tenant Info"
                : null}
            </Typography>
            Are you sure you want to Delete tenant{" "}
            {<b>{itemName}</b>}?
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "right",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleModalClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.cancelBtn}
                onClick={() => confirmDelete()}
              >
                Delete
              </Button>
            </div>
          </Box>
        </Modal>
      ) : null}

      {editModalOpen ? (
        <Modal
          open={editModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              variant="h5"
              className={classes.margin}
            >
              {selectedId ? "Edit" : "Add"}{" "}
              {activeTab === 0
                ? "Tenant Info"
                : null}{" "}
              {itemName}
            </Typography>
            <Typography
              className={classes.tabspace}
            >
              <TextField
                fullWidth
                id="name-input"
                variant="standard"
                name="name"
                label="Company Name"
                type="text"
                value={formValues.name}
                onChange={handleInputChange}
              />{" "}
            </Typography>
            <Typography
              className={classes.tabspace}
            >
              {formValues?.email?.map(
                (value, index) => (
                  <Input
                    fullWidth
                    error={!!error}
                    id={`email-input-${index}`}
                    variant="standard"
                    name={`email-input-${index}`}
                    placeholder="email@gmail.com"
                    type="email"
                    value={value}
                    onChange={(e) => {
                      let emailValues =
                        formValues.email;
                      emailValues[index] =
                        e.target.value;
                      setFormValues({
                        ...formValues,
                        email: emailValues,
                      });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              email: [
                                ...formValues.email,
                                "",
                              ],
                            });
                          }}
                        >
                          <PersonAddAlt1Icon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (
                              formValues.email
                                .length > 1
                            ) {
                              formValues.email.splice(
                                index,
                                1
                              );
                              setFormValues({
                                ...formValues,
                                email: [
                                  ...formValues.email,
                                ],
                              });
                            }
                          }}
                        >
                          <PersonRemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )
              )}
            </Typography>
            <Typography
              className={classes.tabspace}
            >
              {formValues?.TId?.map(
                (value, index) => (
                  <>
                    <Input
                      fullWidth
                      id={`tid-input-${index}`}
                      variant="standard"
                      name={`tid-input-${index}`}
                      type="text"
                      placeholder="Write a company domain here..."
                      autofill={true}
                      value={value}
                      onChange={(e) => {
                        let TIdValues =
                          formValues.TId;
                        TIdValues[index] =
                          e.target.value;
                        setFormValues({
                          ...formValues,
                          TId: TIdValues,
                        });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              getData(index)
                            }
                          >
                            <Button>FETCH</Button>
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              setFormValues({
                                ...formValues,
                                TId: [
                                  ...formValues.TId,
                                  "",
                                ],
                              });
                            }}
                          >
                            <AddIcon />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              if (
                                formValues.TId
                                  .length > 1
                              ) {
                                formValues.TId.splice(
                                  index,
                                  1
                                );
                                formValues.tenantIds.splice(
                                  index,
                                  1
                                );
                                setFormValues({
                                  ...formValues,
                                  TId: [
                                    ...formValues.TId,
                                  ],
                                  tenantIds: [
                                    ...formValues.tenantIds,
                                  ],
                                });
                              }
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography fontSize="15px">
                      {
                        formValues?.tenantIds[
                          index
                        ]
                      }
                    </Typography>
                  </>
                )
              )}
            </Typography>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "right",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleModalClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => confirmAddEdit()}
                className={classes.cancelBtn}
                disabled={
                  !formValues?.name?.trim() ||
                  (!formValues?.TId &&
                    formValues?.TId !== [] &&
                    formValues?.TId === "") ||
                  (!formValues?.email &&
                    formValues?.email !== [] &&
                    formValues?.email === "") ||
                  formValues?.TId.length !==
                    formValues.tenantIds.length
                }
              >
                {selectedId ? "Edit" : "Add"}
              </Button>
            </div>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default CompanyList;
