export const DefaultValues = {
  fontSize: {
    sl: '12px',
    xs: '14px',
    small: "16px",
    md: "18px",
    medium: "22px",
    large: "24px",
  },
  colorSet1: {
    primary: "#c94b0b",
    hovercolor: "#1cca61",
    text: "#656565",
    darkText: "#272727",
    error: "red",
  }
};
