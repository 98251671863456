import React from "react";
import { BrowserRouter as Router, Switch , Route} from "react-router-dom";
import { Redirect } from "react-router-dom";
import Header from "./Components/Header";
import Landing from "./Pages/Landing";
import CompanyList from "./Pages/CompanyList";

export default function App() {  

  let t = localStorage.getItem('hitchhiker_token');
  return (    
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          {t ? <Redirect to='CompanyList' /> : <Landing />}
        </Route>
        <Route><CompanyList /></Route>
      </Switch>
    </Router>
  );
}
