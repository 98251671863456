import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";


require('dotenv').config()

const config = {
  auth: {
    clientId: `257de095-b7ef-490f-a27b-a6c3a9da65ce`,
    authority: `https://login.microsoftonline.com/2c00ca9c-25eb-427e-bc68-e7091acd3643/`,
  },
  cache: {
    cacheLocation:"localStorage",  
  },
};

const publicClientApplication = new PublicClientApplication(config);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={publicClientApplication} >
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
