import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon, 
  Divider,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  Logout, 
} from "@mui/icons-material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import axios from "axios";
import { baseURL } from "../Db/axios";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import MS_login from "../../src/Assets/MS_login.png";
import * as microsoftTeams from "@microsoft/teams-js";

import Logo from "../Assets/Logo.png";
import { DefaultValues } from "../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #dedede",
    borderRadius: "0",
    marginTop: "4px",
    padding: "8px 20px",
    justifyContent: "space-between",  
    paddingTop: "4px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      flexWrap: "nowrap",
    },
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  logoBox: {
    width: "100px",
    marginRight: "18px",
    "& a": {
      display: "block",
    },
  },
  logo: {
    width: "100%",
    paddingBottom: "9px",
    paddingTop: "4px",
  },
  logoSlogan: {
    color: `${DefaultValues.colorSet1.text}`,
    fontSize: `${DefaultValues.fontSize.md}`,
    [theme.breakpoints.down("sm")]: {
      fontSize: `${DefaultValues.fontSize.xs}`,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  headerSubContent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  subTextWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "22px",
    marginBottom: "0",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2px",
    },
  },
  subText: {
    color: `${DefaultValues.colorSet1.text}`,
    fontSize: `${DefaultValues.fontSize.md}`,
    marginBottom: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: `${DefaultValues.fontSize.xs}`,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  icon: {
    marginRight: "8px",
    color: `${DefaultValues.colorSet1.text}`,
  },
  iconReply: {
    transform: "scaleX(-1)",
    marginRight: "8px",
    color: `${DefaultValues.colorSet1.text}`,
  },
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  
  let [isTeamUser, setIsTeamUser] = useState(false);
  let [token, setToken] = useState(null);
 

  function getAuthTokenORContext() {
    return new Promise((resolve, reject) => {
      microsoftTeams.initialize();
      microsoftTeams.authentication.getAuthToken({
        successCallback: (result) => {
          microsoftTeams.getContext(context => {
            resolve({ms_token: result, context})
          }); 
        },
        failureCallback: (error) => {
          reject(error)
        }
      });
    });
  }

  useEffect(() => {
    let t = localStorage.getItem('hitchhiker_token');
    if (!t && history.location.pathname !== "/") {
      history.push("/");
    } else if (!t && history.location.pathname === "/") {
      getAuthTokenORContext().then(response => {
        const {ms_token, idTokenClaims:{aud}, context: {userPrincipalName,username, correlationId,  userObjectId, tid}} = response;
        localStorage.getItem('hitchhiker_token', ms_token)
        setToken(ms_token);
        setIsTeamUser(true);
        localStorage.setItem("role", "admin");
        localStorage.setItem("team_user", "true");
        localStorage.setItem("hitchhiker_name", userPrincipalName)
        axios.post(
          `${baseURL}/SuperAdmin_MS/save`,
          {
            name: userPrincipalName,
            email: username,
            correlationId: correlationId,
            clientId: aud,
            uniqueId: userObjectId,
            tenantId: tid,
            accessToken: ms_token,
          }
        );
        history.push("/CompanyList");
      }).catch((e) => { console.log(e)});
    }else{
      setToken(t)
    }   
  },[history]);

  function WelcomeTeamUser() {
    let name = localStorage.getItem('hitchhiker_name');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
            >
              <Avatar
                sx={{ width: 32, height: 32 }}
              >
                {" "}
                {name && name[0].toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter:
                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform:
                  "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <MenuItem>
            <Avatar /> {name}
          </MenuItem>
          <Divider />
          {localStorage.getItem("role") === "admin" || token ? <MenuItem 
              onClick={() =>  history.push("/CompanyList") }>
              <ListItemIcon>
                <ManageAccountsIcon fontSize="small" />
              </ListItemIcon>
              Manage CompanyList
            </MenuItem> : null}
          </Menu>
      </React.Fragment>
    );
  }

  function WelcomeBrowserUser() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { accounts, instance } = useMsal();
    if (accounts && accounts.length) {
      setIsTeamUser(false);
      const name = accounts[0].name;
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      return (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
              >
                <Avatar
                  sx={{ width: 32, height: 32 }}
                >
                  {" "}
                  {name && name[0].toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter:
                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform:
                    "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem>
              <Avatar /> {name}
            </MenuItem>
            <Divider />
              {localStorage.getItem("role") === "admin" ? <MenuItem 
                onClick={() =>  history.push("/CompanyList") }>
                <ListItemIcon>
                  <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                Manage CompanyList
              </MenuItem> : null}
            <MenuItem
              onClick={() =>
                handleLogout(instance)
              }
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  async function signInClickHandler(instance) {
      const data = await instance.loginPopup({
        prompt: "select_account",
      });
      if(data.account.username.includes(`yeap.tv`)){
        try{
        const { 
          accessToken,
          account: { name, username, idTokenClaims:{aud} , correlationId },
          tenantId,
          uniqueId,
        } = data;
        axios.post(
          `${baseURL}/SuperAdmin_MS/save`,
          {
            name,
            email: username,
            correlationId,
            clientId: aud,
            uniqueId,
            tenantId,
            accessToken
          }
        );
        localStorage.setItem("hitchhiker_token", accessToken);
        localStorage.setItem("team_user", "false");
        setToken(accessToken);
        setIsTeamUser(false);
        history.push("/CompanyList");
      } catch (e) {
        console.log(e);
      } 
      }else{
        console.log("invalid");
      }
      
  }
  function SignInButton() {
    const { instance } = useMsal();
    return (
      <img
        src={MS_login}
        onClick={() =>
          signInClickHandler(instance)
        }
        alt=""
      />
    );
  }

  // function handleLogout(instance) {
  //   instance
  //     .logoutPopup()
  //     .then((x) => {
  //       localStorage.clear();
  //       setToken(null);
  //       history.replace("/");
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // }

  function handleLogout() {
    localStorage.clear();
    setToken(null);
    window.location.reload()
    history.replace("/");
}

  return (
    <Grid container className={classes.root}>
      <Box className={classes.logoWrapper}>
        <Grid item className={classes.logoBox}>
          <img
            alt="Hitchhiker"
            className={classes.logo}
            src={Logo}
          />
        </Grid>
        <Typography
          className={classes.logoSlogan}
        >
          Marketplace for learning and networking
        </Typography>
      </Box>
      <Box className={classes.headerSubContent}>
        {isTeamUser && token ?
            <WelcomeTeamUser/> 
            : 
              <Typography
              variant="subtitle1"
              className={classes.subTextWrapper}
              >
              <AuthenticatedTemplate>
                <WelcomeBrowserUser />
              </AuthenticatedTemplate>

              <UnauthenticatedTemplate>
                <SignInButton />
              </UnauthenticatedTemplate>
             </Typography>
        }
      </Box>
    </Grid>
  );
}
